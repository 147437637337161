import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useAuth } from "../context/auth";
import { supabase, logRepActivity } from "../helpers/supabase";
import { AuthProvider } from "../context/auth";
import { navigate } from "gatsby";
import { RingLoader } from "react-spinners";

import "../styles/styles.scss";

import {
  loginContainerStyles,
  loginBoxStyles,
  loginFieldStyles,
} from "../styles/styles";
import { FaLock } from "react-icons/fa";
import { logDev } from "../helpers/globalHelpers";
import Helmet from "react-helmet";

const ConfirmCode = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { user, setMfa } = useAuth();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (loading) {
      sendCode();
    }
  });

  const sendCode = () => {
    supabase.auth.signIn({ email: user.email }).then((response) => {
      logDev("OTP email sent");
      setLoading(false);
      if (response.error) {
        alert(`Error: ${response.error.message}`);
      }
    });
  };

  const onSubmit = (data) => {
    logDev("data", data);
    setSubmitting(true);
    supabase.auth
      .verifyOTP({
        email: user.email,
        token: data.code,
        type: "magiclink",
      })
      .then((response) => {
        setSubmitting(false);
        logDev(response);
        if (!response.error) {
          setMfa(true);
          logRepActivity(user.email, "logIn").then((res) => {});
          navigate("/");
        } else {
          alert(`Error: ${response.error.message}`);
        }
      });
  };

  const maskEmail = (email) => {
    const [_, suffix] = email.split("@");
    return `****@${suffix}`;
  };

  if (!user) {
    navigate("/");
    return <div />;
  } else {
    return (
      <div
        class="section"
        style={loginContainerStyles}
      >
        <Helmet title="Confirm Code | Frontier Solutions" />
        <div class="columns is-centered">
          <div class="column is-4-desktop is-6-tablet">
            {loading ? (
              <div class="has-text-centered">
                <RingLoader />
              </div>
            ) : (
              <form
                class="box"
                style={loginBoxStyles}
                onSubmit={handleSubmit(onSubmit)}
              >
                <p class="title has-text-centered">Account Verification</p>
                <p class="is-size-7">
                  Protecting your data is our top priority. Please confirm your
                  account by entering the code sent to{" "}
                  <strong>{maskEmail(user.email)}</strong>
                </p>
                <div
                  class="field"
                  style={loginFieldStyles}
                >
                  {/* <label class="label">Code</label> */}
                  <div class="control has-icons-left">
                    <input
                      class="input"
                      {...register("code", { required: true })}
                    />
                    <span class="icon is-small is-left">
                      <FaLock />
                    </span>
                  </div>
                </div>

                <div
                  class="field"
                  style={loginFieldStyles}
                >
                  <button
                    class="button is-fullwidth fpLoginButton"
                    type="submit"
                  >
                    {submitting ? <RingLoader size={30} /> : "Submit"}
                  </button>
                </div>
                <div
                  class="field"
                  style={loginFieldStyles}
                >
                  <p class="has-text-centered">
                    Didn't receive a code? <br />
                    <a
                      onClick={() => {
                        // This triggers useEffect() which calls sendCode
                        setLoading(true);
                      }}
                    >
                      Resend code
                    </a>
                  </p>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
};

const ConfirmCodePage = () => (
  <AuthProvider>
    <ConfirmCode />
  </AuthProvider>
);

export default ConfirmCodePage;
